import ButtonAppBar from "./AppBar";
import React from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KU from "./images/KU.svg";

// Create responsive themes
let theme = createTheme({
  typography: {},
  palette: {
    primary: {
      main: "#4caf50", // Change to desired color
    },
  },
});
theme = responsiveFontSizes(theme);

// Education data
const educationData = [
  {
    year: "2021-2023",
    title: "Masters of Computer Science at University of Copenhagen",
    description: `During my MSc in Computer Science, I delved into advanced program and system development, building on mathematical, statistical, and logical frameworks. I became adept at implementing complex systems, meeting external quality and performance constraints. My skills extended to combining theoretical computer science methods for real-world applications in diverse sectors like healthcare and environmental sciences. Not only did I contribute to scientific understandings by selecting, combining, and even refining theories and methods, but I also mastered objective evaluation and academic documentation of my findings. My competencies included overseeing intricate scientific or organizational contexts and participating in large-scale system development, always adhering to best practices and ethical considerations.`,
    logo: KU,
    courses: [
      "Advanced Programming",
      "Advanced Algorithms and Data Structures",
      "Advanced Computer Systems",
      "Project outside Course Scope: Developing a Padel Community App",
      "Signal and Image Processing",
      "Numerical Optimization",
      "Machine Learning",
      "Applied Machine Learning",
      "Advanced Deep Learning",
      "Master's Thesis: Automatic Quality Assessment and Pathology Detection of Magnetic Resonance Imaging",
    ],
  },
  {
    year: "2018-2021",
    title: "Bachelors of Computer Science at University of Copenhagen",
    description: `In my BSc program in Computer Science with a specialization in data science, 
                  I acquired a deep understanding of data structures, algorithms, and computer 
                  systems. I gained skills in data processing, problem-solving, and programming 
                  in multiple languages. I also became proficient in applied mathematics and statistics, 
                  focusing on automated and advanced data analysis methods. Beyond technical skills, 
                  I honed my ability to collaborate across disciplines, effectively communicate my 
                  findings, and critically evaluate the social and ethical implications of data usage. 
                  Overall, I learned to apply a holistic approach to tackling complex, data-driven problems.`,
    logo: KU,
    courses: [
      "Programming and Problem Solving",
      "Discrete Mathematics and Algorithms",
      "Interaction Design",
      "Linear Algebra in Computer Science",
      "Software Development",
      "Mathematical Analysis and Probability Theory for Computer Scientists",
      "Modelling and Analysis of Data",
      "Computer Systems",
      "Data Science",
      "Algorithms and Data Structures",
      "Randomized Algorithms for Data Analysis",
      "Elements of Machine Learning",
      "Philosophy of Computer Science",
      "Introduction to Economics",
      "Logic in Computer Science",
      "Bachelor Project in Computer Science: Using Machine Learning to Predict Expected Goals in Football",
    ],
  },
  {
    year: "2015-2017",
    title: "Bachelors of Law at University of Copenhagen (not completed)",
    description: `In my law program, I studied both Danish and international legal affairs. 
                    I had the option to specialize in a specific legal area such as property law or criminal law, 
                    where I learned about the presentation of evidence and argumentation. The aim of my bachelor's degree 
                    in law was to continue with further education in a master's program. However, at the time, a restriction 
                    on the number of allowed bachelors degrees was introduced which led me to drop out of my law studies`,
    logo: KU,
    courses: [
      "Judicial System and Legal Methodology",
      "Criminal Law",
      "Tort and contract",
      "Law of Property",
      "Law of Contracts and Private International Law",
      "Fundamental Rights of the Individual",
      "International Law",
      "Family Law and Law of Wills and Succession",
      "Legal Histroy",
      "Constitutional Law and EU-Constitutional Law: Institutions",
      "Mortgages and creditor's rights",
      "General Administrative Law",
      "EU-law",
      "Global Justice",
      "Information Law",
    ],
  },
];

// Education component
export default function Education() {
  return (
    <>
      <ButtonAppBar />
      <Grid container spacing={1} padding={0}>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            style={{
              padding: "2%",
              background: "#FFFFFF",
              borderRadius: "15px",
              margin: "2%",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                color="#000000"
                padding="2%"
                style={{
                  fontFamily: "'Urbanist', sans-serif",
                  fontWeight: 500,
                }}
              >
                Education
              </Typography>
              {educationData.map((education, index) => (
                <Accordion
                  key={index}
                  style={{
                    margin: "1% 0",
                    borderRadius: "10px",
                    background: "#F8F9F9",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <Avatar
                          alt={education.title}
                          src={education.logo}
                          style={{ marginRight: "16px", borderRadius: "50%" }}
                        />
                        <Typography
                          style={{
                            fontFamily: "'Urbanist', sans-serif",
                            fontWeight: 100,
                          }}
                        >
                          {education.title}
                        </Typography>
                      </div>
                      <div style={{ marginRight: "24px" }}>
                        {" "}
                        {/* <- Adjust the margin as needed */}
                        <Typography
                          sx={{ color: "text.secondary" }}
                          style={{
                            fontFamily: "'Urbanist', sans-serif",
                            fontWeight: 100,
                          }}
                        >
                          {education.year}
                        </Typography>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderRadius: "0 0 10px 10px" }}>
                    <div>
                      <Typography
                        variant="body1"
                        style={{
                          fontFamily: "'Urbanist', sans-serif",
                          fontWeight: 100,
                        }}
                      >
                        {education.description}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          marginTop: "16px",
                          fontFamily: "'Urbanist', sans-serif",
                          fontWeight: 500,
                        }}
                      >
                        Completed Courses
                      </Typography>{" "}
                      {/* Made it bold */}
                      <List>
                        {education.courses.map((course, i) => (
                          <ListItem key={i}>
                            <ListItemText
                              primary={course}
                              primaryTypographyProps={{
                                style: {
                                  fontSize: "0.9rem",
                                  color: "grey",
                                  fontFamily: "'Urbanist', sans-serif",
                                  fontWeight: 100,
                                },
                              }} // Adjusted size and color
                            />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </ThemeProvider>
          </Paper>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
      </Grid>
    </>
  );
}
