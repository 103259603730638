import React from "react";
import ButtonAppBar from "./AppBar";
import { Grid, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import Magnus from "./images/magnus.png";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  margin: "2%",
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "60%",
  borderRadius: "10px",
  overflow: "hidden", // this ensures that the image doesn't spill outside
}));

const Bio = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  background: "#FFFFFF",
  padding: theme.spacing(2),
  margin: "2%",
  marginBottom: "20%",
  textAlign: "left",
  color: theme.palette.text.secondary,
  height: "auto",
  borderRadius: "10px",
  overflow: "hidden", // this ensures that the image doesn't spill outside
}));

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  borderRadius: "5px", // matching the border radius of the Paper component
  objectFit: "cover", // ensures the image covers the whole space
});

export default function Home() {
  return (
    <>
      <ButtonAppBar />
      <Grid container spacing={1} padding={0}>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Item>
            <StyledImage
              src={Magnus}
              srcSet={`${Magnus}?w=64&h=64&fit=crop&auto=format&dpr=2 2x`}
              alt={"Magnus"}
              loading="lazy"
            />
          </Item>
          <Bio>
            <Typography
              color="#000000"
              style={{
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 100,
              }}
            >
              Hey there! I'm Magnus, and I'm currently working as a Data
              Scientist at twoday in Copenhagen. I thrive at the intersection
              between software development and machine learning. As an innately
              curious individual, I enjoy diving deep into various {"("}
              primarily scientific{")"} subjects. When I'm not buried in data, I
              like spending my time playing and watching tennis, padel and
              football, being with friends and family and spending time in
              nature.
            </Typography>
          </Bio>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
      </Grid>
    </>
  );
}
