import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import CV from "./Components/CV";
import Contact from "./Components/Contact";
import Education from "./Components/Education";

function App() {
  useEffect(() => {
    document.body.style.backgroundColor = "#e2e2e2";
    // Cleanup
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/about" element={<Home />} />
        <Route path="/" element={<Navigate replace to="/about" />} />
        <Route path="/cv" element={<CV />} />
        <Route path="/education" element={<Education />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
