import ButtonAppBar from "./AppBar";
import React from "react";
import { Grid, Typography, Paper, Avatar } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Facebook from "./images/facebook.png";
import LinkedIn from "./images/linkedin.jpeg";
import Instagram from "./images/instagram.svg";
import Github from "./images/github.svg";

// Create responsive themes
let theme = createTheme({
  typography: {},
  palette: {
    primary: {
      main: "#4caf50", // Change to desired color
    },
  },
});
theme = responsiveFontSizes(theme);

// Social Media data
const socials = [
  {
    name: "LinkedIn",
    logo: LinkedIn,
    link: "https://linkedin.com/in/magnus-diamant-566b261a2",
  },
  {
    name: "GitHub",
    logo: Github,
    link: "https://github.com/MagnusDiamant",
  },
  {
    name: "Instagram",
    logo: Instagram,
    link: "https://www.instagram.com/magnusdiamant/",
  },
  {
    name: "Facebook",
    logo: Facebook,
    link: "https://www.facebook.com/magnus.diamant/",
  },
];

// Contact component
export default function Contact() {
  return (
    <>
      <ButtonAppBar />
      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            style={{
              padding: "2%",
              background: "#FFFFFF",
              borderRadius: "15px",
              margin: "2%",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                color="#000000"
                padding="2%"
                style={{
                  fontFamily: "'Urbanist', sans-serif",
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Contact
              </Typography>
              <Typography
                variant="body1"
                padding="1%"
                style={{
                  fontFamily: "'Urbanist', sans-serif",
                  fontWeight: 100,
                  color: "#000000",
                }}
              >
                Feel free to reach out to me via email at{" "}
                <a
                  href="mailto:magnusdiamant@gmail.com"
                  style={{ color: "#000000" }}
                >
                  magnusdiamant@gmail.com
                </a>{" "}
                or through the social media platforms listed below.
              </Typography>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                marginTop="2%"
              >
                {socials.map((social, index) => (
                  <Grid
                    item
                    key={index}
                    xs={6}
                    sm={3}
                    style={{ textAlign: "center" }}
                  >
                    <a
                      href={social.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                        fontFamily: "'Urbanist', sans-serif",
                        fontWeight: 500,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar alt={social.name} src={social.logo} />
                      <Typography variant="body2">{social.name}</Typography>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </ThemeProvider>
          </Paper>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
      </Grid>
    </>
  );
}
